import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import moment from 'moment';

import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Underline,
  Alignment,
  FontSize
} from 'ckeditor5';
import { Days, Months, Years } from "../../../shared/data/policy";
import { FacadeService } from "../../../shared/services/facade/facade.service";
import { customerStepFields, extrasStepFields, insuranceStepFields } from "./util-fields";
import { ActivatedRoute, Router } from "@angular/router";
import { booleanTrueValidator } from "../../../shared/validators/booleanTrue";
import { SimplebarAngularComponent } from "simplebar-angular";
import { NgSelectComponent } from '@ng-select/ng-select';
import { states } from 'src/app/shared/data/states';
import { FirebaseAuth } from '../../../shared/services/FirebaseAuth/firebase-auth.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { tap } from 'rxjs/operators';
import { UtilService } from '../../../shared/services/util/util.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { phoneNumberValidator } from '../../../shared/validator/phoneNumberValidator';

@Component({
  selector: 'app-new-policy',
  templateUrl: './new-policy.component.html',
  styleUrl: './new-policy.component.scss'
})
export class NewPolicyComponent implements OnInit, AfterViewInit {
    @ViewChild('searchContactSelect', { static: false}) searchContactSelect: NgSelectComponent | undefined;
    @ViewChild('customerTab', { static: false}) customerTabButton: ElementRef | undefined;
    @ViewChild('membersTab', { static: false}) membersTabButton: ElementRef | undefined;
    @ViewChild('insuranceTab', { static: false}) insuranceTabButton: ElementRef | undefined;
    @ViewChild('extrasTab', { static: false}) extrasTabButton: ElementRef | undefined;
    @ViewChild('scrollCommentsContainer') scrollContainerRef!: SimplebarAngularComponent;
    showScrollToBottomButton = false;

    selectedStatus: any;
    policyStatus: any;

    states = states;
    gender = states;

    filtered_contacts: any[] = [];
    contacts_all: any[] = [];

    selectedContact = 'Select a contact';
    hiddenFindContact = true;

    public showNewDoc = false;
    public id: string | null = '';
    public Editor = ClassicEditor;
    public config = {
      toolbar: [ 'undo', 'redo', 'bold', 'italic', 'underline', '|', 'fontsize', 'alignment', '|', ],
      shouldNotGroupWhenFull: true,
      plugins: [
        Undo, Bold, Italic, Essentials, Mention, Paragraph, Underline, Alignment, FontSize
      ]
    }
    activeStepIndex = 0;
    public activeDetailIndex = 0;
    public dataSets: any;

    public isHovering: boolean = false;
    files: File[] = [];
    policy_files: any[] = [];

    policy_comments: any[] = [];
    number_comments = 0;
    new_comments = false;

    customerPolicyForm!: FormGroup;

    fieldTextType!: boolean;
    cvvTextType!: boolean;
    breadCrumbItems!: Array<{}>;
    submit!: boolean;
    selected_payment_method = "";
    current_number_members = 1;
    current_member = 0;
    current_policy = null

    days = Days;
    months = Months
    years = Years;
    disabledForm = true;
    isAdmin = false;
    isProcessor = false;
    isAgent = false;
    current_user: any = {};
    permissions: any[] = [];

    constructor(private facade: FacadeService,
                private fb: FormBuilder,
                private modalService: NgbModal,
                private functions: AngularFireFunctions,
                private firebaseAuth: FirebaseAuth,
                private utilService: UtilService,
                private router: Router,
                private route: ActivatedRoute) {
      this.facade.spinner.show();
        this.id = this.route.snapshot.paramMap.get('id');

        this.utilService.getAuthStateWithClaims().subscribe(({ user, claims }) => {
          this.isAdmin = claims?.role === 'admin';
          this.isProcessor = claims?.role === 'processor';
          this.isAgent = claims?.role === 'agent';
        });

        this.facade.contactService.getContacts().subscribe((data) => {
          this.contacts_all = data.map((i: any) => { i.fullSearch = i.first_name + ' ' + i.last_name + ' | ' + i.email + ' | ' + i.phone_number; return i; });
        });

        this.facade.agentService.getAuthAgent().subscribe((data: any) => {
          this.current_user = data;
          this.permissions = data?.permissions;
          this.current_user['full_name'] = `${data.first_name} ${data.last_name}`;
        });

        this.facade.dataSetsService.getDataSets().pipe(
          tap((data: any) => {
            this.dataSets = data;
            this.dataSets['yes_no']= [ { value: 'yes', name: 'Yes' }, { value: 'no', name: 'No' } ];
          })
        ).subscribe(() => {
          if(this.id) {
            this.facade.policyService.getPolicy(this.id).subscribe((data: any) => {
              this.current_policy = data;

              this.membersArrayForm.clear();

              for(let i = 0; i < data.members.length; i++) {
                const isSpouse = data.members[i].type_dependency.toLowerCase() === 'spouse';
                this.addNewMember(isSpouse);
              }

              this.selected_payment_method = data.payment_type;
              this.policyStatus = data.status;
              this.customerPolicyForm.patchValue(data);

              this.fillNPNs(data.company);

              if ((!this.isAgent && data.status === 'by_correction') || (data.status === 'to_process' || data.status === 'processing' || data.status === 'processed' || data.status === 'archived' || data.status === 'cancelled')) {
                this.disabledForm = true;
                /*this.customerPolicyForm.disable();*/
              } else {
                this.disabledForm = false;
                this.customerPolicyForm.enable();
              }
            }, (error) => {
              this.facade.messageService.add({ severity: 'error', summary: 'Error', detail: 'Policy not found' });
              this.router.navigateByUrl('/policies');
            });

            this.facade.commentsService.getCommentsByPolicy(this.id).subscribe((data: any) => {
              this.policy_comments = data;
              this.number_comments = data.length;
              this.new_comments = data.some((comment: any) => !comment.read && comment.align === 'right');
            });

            this.facade.policyService.getPolicyFiles(this.id).subscribe((data: any) => {
              this.policy_files = data;
            });
          } else {
            this.disabledForm = false;
          }

          this.facade.spinner.hide();
        });
    }

    ngAfterViewInit() {
        // this.scrollContainerRef.SimpleBar.getScrollElement().addEventListener('scroll', this.onScroll.bind(this));
    }

  onTypeDependencyChange(event: any, formGroup: AbstractControl) {
    if (event.value === 'spouse') {
      formGroup.get('annual_income')?.setValidators(Validators.required);
    }
  }

    viewConsentLink() {
      if (this.current_policy && this.current_policy['consent_link']) {
        window.open(this.current_policy['consent_link'], '_blank');
      } else {
        this.facade.messageService.add({ severity: 'error', summary: 'Error', detail: 'Consent link not available' });
      }
    }

    copyConsentLink() {
      const el = document.createElement('textarea');
      el.value = `${this.current_policy?.['consent_link']}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.facade.messageService.add( { severity: 'success', summary: 'Success', detail: 'Consent link copied successfully' });
    }

  forwardConsentLink() {
      this.facade.spinner.show();
      const callable = this.functions.httpsCallable('forwardConsentLink');
      this.firebaseAuth.getTenantId().then((tenantId) => {
        callable(
          {
            agencyId: tenantId,
            policyId: this.id
          }
        ).subscribe((result: any) => {
          this.facade.spinner.hide();
          if(!result) {
            this.facade.messageService.add( { severity: 'error', summary: 'Error', detail: 'Consent could not be forwarded' });
            return;
          }

          this.facade.messageService.add( { severity: 'success', summary: 'Success', detail: 'Consent was successfully forwarded' });
        }, (error) => {
          this.facade.spinner.hide();
          console.error('Error forwarding consent:', error);
        });
      }).catch((error) => {
        console.error('Error getting tenant ID:', error);
        this.facade.spinner.hide();
      });
    }

    onScroll() {
        const scrollElement = this.scrollContainerRef.SimpleBar.getScrollElement();
        const isScrolledToBottom = scrollElement.scrollHeight - scrollElement.scrollTop === scrollElement.clientHeight;
        this.showScrollToBottomButton = !isScrolledToBottom;
    }

    scrollToBottom() {
        // const scrollElement = this.scrollContainerRef.SimpleBar.getScrollElement();
        // scrollElement.scrollTop = scrollElement.scrollHeight;
    }

    ngOnInit() {
        let title = (this.id === null || this.id === '') ? 'New Policy' : 'Policy'
        this.breadCrumbItems = [
            { label: 'Policies' },
            { label: title, active: true }
        ];

        this.route.queryParams.subscribe(params => {
          const tabIndex = params['step'];
          if (tabIndex !== undefined && tabIndex === 'extras') {
            this.activeStepIndex = 4
          }
        });

        this.initializeForm();

        setTimeout(() : void => {
          this.addDoubleClickEvent();
        }, 3000)
    }


    addDoubleClickEvent(): void {
      document.addEventListener('dblclick', (event : MouseEvent) => {
        if (this.disabledForm) {
          this.copyToClipboard(event);
        }
      });
    }

    copyToClipboard(event: Event): void {
      const input = event.target as HTMLInputElement;
      if (input && input.value) {
        let valueToCopy = input.value;
        if (moment(valueToCopy, 'YYYY-MM-DD', true).isValid()) {
          valueToCopy = moment(valueToCopy).format('MM-DD-YYYY');
        }

        navigator.clipboard.writeText(valueToCopy).then((): void => {
          console.log('Copied to clipboard:', valueToCopy);
        }).catch(err => {
          console.error('Failed to copy:', err);
        });
      }
    }

    updateUrlWithActiveTabIndex(index: number) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { tab: index },
        queryParamsHandling: 'merge'
      });
    }

    fillNPNs(company: string) {
      if(!this.dataSets?.['npns']) {
        return;
      }

      this.dataSets['npns'] = [];

      if(this.current_user.npn_number && !this.current_user.npn_number.includes('0000')) {
        this.dataSets['npns'].push({ value: this.current_user.npn_number, name: `${this.current_user.first_name} ${this.current_user.last_name} - ${this.current_user.npn_number}` });
      }

      if(company === 'Select Health') {
        this.dataSets['npns'].push({ value: '19546693', name: 'Bettania Ramos - 19546693' });
      } else {
        this.dataSets['npns'].push({ value: '19546579', name: 'Adalberto Paz - 19546579' });
      }
    }

    toggleHover(event: boolean) {
        this.isHovering = event;
    }

    uploadComplete(file: any) {
      this.files = this.files.filter(f => f !== file);
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            const file = files.item(i) as File;

            if (file.type === 'application/pdf' || file.type === 'image/jpeg') {
                this.files.push(file);
            } else {
              this.facade.messageService.add({ severity: 'error', summary: 'Error', detail: `The file "${file.name}" is not a PDF or JPG. Only PDF and JPG files are allowed.` });
            }
        }
    }

    onFileSelected(event: any) {
      const files = event.target.files;

      this.onDrop(files);
    }

    initializeForm() {
        this.customerPolicyForm = this.fb.group({
            first_name: ['', Validators.required],
            middle_name: [''],
            last_name: ['', Validators.required],
            second_last_name: [''],
            date_of_birth: ['', Validators.required],
            gender: ['', Validators.required],
            phone_number: ['', [phoneNumberValidator()]],
            email: ['', Validators.required],
            legal_status: [''],
            ssn: [''],
            green_card: [''],
            work_permit: [''],
            civil_status: [''],
            applicant_member: ['', Validators.required],
            language: [''],
            period: ['', Validators.required],
            address_1: ['', Validators.required],
            address_2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            postal_code: ['',Validators.required],
            county: ['', Validators.required],
            name_of_employer: ['', Validators.required],
            employer_phone: [''],
            position_occupation: ['', Validators.required],
            annual_income: ['', Validators.required],
            number_applicants: ['', Validators.required],
            number_members: ['', Validators.required],
            number_dependents: ['', Validators.required],
            npn_responsible: ['', Validators.required],
            policy_number: [''],
            contact_identification: [''],
            effective_date: ['', Validators.required],
            company: ['', Validators.required],
            insurance_plan: ['', Validators.required],
            type_plan: [''],
            status: ['draft'],
            total_cost: [''],
            tax_credit_subsidy: [''],
            monthly_premium_amount: ['', Validators.required],
            recurring_payment: [''],
            preferred_pay_day: [''],
            payment_type: [''],
            card_type: [''],
            card_number: [''],
            expiration_month: [''],
            expiration_year: [''],
            cvv: [''],
            bank_name: [''],
            routing_number: [''],
            account_number: [''],
            send_consent: [''],
            signed_consent: [''],
            extra_notes: [''],
            created_at: [''],
            updated_at: [''],
            consent_link: [''],
            i_agree: [true, [Validators.required, booleanTrueValidator()]],
            members: this.fb.array([])
        });
    }

    get membersArrayForm(): FormArray {
        return (this.customerPolicyForm.get('members') as FormArray);
    }

    membersDeleteRow(index: number) {
        this.membersArrayForm.removeAt(index);
    }

    get membersRowsControls(): AbstractControl[] {
        return this.membersArrayForm.controls;
    }

    getFormGroupAtIndex(index: number) {
        return (this.membersRowsControls[index] as FormGroup);
    }

    onUploadSuccess(event: any) {
        console.log(event);
    }

    addNewMember(isSpouse: boolean = false) {
        this.current_number_members += 1

        let typeDependencyValidator = isSpouse ? Validators.required : null;

        let row = this.fb.group({
            applicant_member: [null, Validators.required],
            type_dependency: [null, Validators.required],
            first_name: [null, Validators.required],
            last_name: [null, Validators.required],
            date_of_birth: [null, Validators.required],
            gender: [null, Validators.required],
            phone_number: [null, [phoneNumberValidator()]],
            email: [null],
            legal_status: [null, Validators.required],
            ssn: [null],
            name_of_employer: [null],
            position_occupation: [null],
            annual_income: [null, typeDependencyValidator]
        });

        this.membersArrayForm.push(row);
    }

    validSubmit(isDraft: boolean = false) {
        this.submit = true;
        let {error_field, in_members} = this.markAllFieldsAsTouched(this.customerPolicyForm);
        error_field = error_field.replace(/[0-9]/g, '');

        if(in_members) {
            this.membersTabButton?.nativeElement.click();
        } else if(customerStepFields.includes(error_field)) {
            this.customerTabButton?.nativeElement.click();
        } else if(insuranceStepFields.includes(error_field)) {
            this.insuranceTabButton?.nativeElement.click();
        } else if(extrasStepFields.includes(error_field)) {
            this.extrasTabButton?.nativeElement.click();
        }

        if(this.customerPolicyForm.valid) {
            const formData = {
                ...this.customerPolicyForm.value
            };

            if(this.id) {
                this.facade.policyService.updatePolicy(this.id, formData, this.current_user, isDraft).subscribe(() => {
                    this.facade.messageService.add({ severity: 'success', summary: 'Success', detail: 'Policy updated successfully' });
                    if(isDraft) {
                        this.router.navigateByUrl('/policies/policy/' + this.id);
                    } else {
                        this.router.navigateByUrl('/policies');
                    }
                });
            } else {
                this.facade.policyService.addPolicy(formData, this.current_user, isDraft).subscribe((policyId) => {
                    if(policyId) {
                        this.facade.messageService.add({ severity: 'success', summary: 'Success', detail: 'Policy created successfully' });
                        this.router.navigateByUrl('/policies/policy/' + policyId + '?step=extras');
                    }
                });
            }
        } else {
            return;
        }
    }

    private markAllFieldsAsTouched(form: FormGroup) {
        let error_field = "";
        let in_members = false;
        Object.keys(form.controls).forEach(field => {
          const controlField = form.get(field) as any;

          if(field === 'members') {
            Object.keys(controlField.controls).forEach(index => {
              const controlFieldIndex = controlField.get(index) as any;

              Object.keys(controlFieldIndex.controls).forEach(fieldIndex => {
                const controlFieldIndexField = controlFieldIndex.get(fieldIndex) as any;

                if(controlFieldIndexField instanceof FormArray) {
                  controlFieldIndexField.controls.forEach((control: any) => {
                    Object.keys(control.controls).forEach(controlFieldIndexField => {
                      const controlFieldIndexFieldControl = control.get(controlFieldIndexField) as any;
                      controlFieldIndexFieldControl.markAsTouched({ onlySelf: true });
                    });
                  });
                } else {
                  controlFieldIndexField.markAsTouched({ onlySelf: true });
                }

                if(controlFieldIndexField.hasError('required') || controlFieldIndexField.hasError('mask') || controlFieldIndexField.hasError('invalidPhoneNumber')) {
                    if(error_field === "") {
                      error_field = fieldIndex;
                      in_members = true;
                    }
                }
              });
            });
          }

          controlField?.markAsTouched({ onlySelf: true });
          if(controlField.hasError('required') || controlField.hasError('mask') || controlField.hasError('invalidPhoneNumber')) {
              if(error_field === "") {
                  error_field = field;
              }
          }
        });

        return {error_field, in_members};
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    toggleCVVTextType() {
        this.cvvTextType = !this.cvvTextType;
    }

    onSelectPayment(event: any) {
        if(event) {
            this.selected_payment_method = event.name;
        } else {
            this.selected_payment_method = "";
        }
    }

    selectMember(member_number: number) {
        this.current_member = member_number;
    }

    showModalDocument(new_document_content: any) {
        this.facade.modalService.open(new_document_content, { size: 'md', centered: true });
    }

    protected readonly Array = Array;

    goTo(index: number) {
        this.activeDetailIndex=index

        if(index === 2) {
            // this.facade.commentsService.checkAllComments(this.id ? this.id : '');
        }
    }

  onSelectionChange(contact: any) {
    if(!contact) {
        return;
    }

    delete contact['created_at'];
    delete contact['updated_at'];

    this.membersArrayForm.clear();

    for(let i = 0; i < contact.members.length; i++) {
      const isSpouse = contact.members[i].type_dependency.toLowerCase() === 'spouse';
      this.addNewMember(isSpouse);
    }

    this.selected_payment_method = contact.payment_type;
    this.customerPolicyForm.patchValue(contact);

    this.hiddenFindContact = true;
    this.clearSearch();
  }

  clearSearch() {
    this.selectedContact = 'Select a contact';
    this.filtered_contacts = [];
    this.searchContactSelect?.handleClearClick();
  }

  searchContact(event: any) {
    if(event.term.toString().length < 3) {
      this.clearSearch();
      return;
    }

    this.filtered_contacts = this.contacts_all.filter((contact: any) => {
      return contact?.first_name?.toLowerCase().includes(event.term.toString().toLowerCase()) ||
        contact?.middle_name?.toLowerCase().includes(event.term.toString().toLowerCase()) ||
        contact?.last_name?.toLowerCase().includes(event.term.toString().toLowerCase()) ||
        contact?.second_last_name?.toLowerCase().includes(event.term.toString().toLowerCase()) ||
        contact?.email?.toLowerCase().includes(event.term.toString().toLowerCase()) ||
        contact?.phone_number?.toString().includes(event.term.toString().toLowerCase()) ||
        contact?.ssn?.toLowerCase().includes(event.term.toString().toLowerCase());
    });
  }

  toNewComment($event: any) {
    this.new_comments = $event;
  }

  toNumberComments($event: any) {
    this.number_comments = $event;
  }

  onChangeCompany($event: any) {
    this.fillNPNs($event.value);
  }

  onChangeStatus($event: any, NoticeSignModal:any) {
    this.selectedStatus = $event.value;

    if (this.selectedStatus === "processing" || this.selectedStatus === "processed") {
      if (!this.current_policy?.['signed_consent'] || (!this.policy_files || this.policy_files.length === 0)) {
        this.signature_attach_validation(NoticeSignModal)
      } else {
        this.updatePolicyStatus(this.selectedStatus);
      }
    } else {
      this.updatePolicyStatus(this.selectedStatus);
    }
  }

  signature_attach_validation(NoticeSignModal: any) {
    this.modalService.open(NoticeSignModal, { centered: true });
  }

  cancelStatusChange(modal: any) {
    this.customerPolicyForm.patchValue({
      status: this.policyStatus
    });
    modal.close('Close click')
  }

  accept_Same_Way() {
    this.facade.policyService.updatePolicyStatus(this.id ? this.id : '', this.selectedStatus).subscribe(() => {
      this.facade.messageService.add({ severity: 'success', summary: 'Success', detail: 'Policy status updated successfully' });
    });
  }

  updatePolicyStatus(status: string) {
    this.facade.policyService.updatePolicyStatus(this.id ? this.id : '', status).subscribe(() => {
      this.facade.messageService.add({ severity: 'success', summary: 'Success', detail: 'Policy status updated successfully' });
    });
  }

  dlClickSelect(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const selectElement = target.closest('ng-select');
    if (selectElement && this.disabledForm) {
      const selectedValue = selectElement.querySelector('.ng-value-label')?.textContent;
      if (selectedValue) {
        navigator.clipboard.writeText(selectedValue).then(() => {
          console.log('Copied to clipboard:', selectedValue);
        }).catch(err => {
          console.error('Failed to copy:', err);
        });
      }
    }
  }
}
