import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) { return null; }

        const valid = /^\d{10}$/.test(control.value);
        return valid ? null : { invalidPhoneNumber: true };
    };
}
