<app-breadcrumbs title="{{(id === null || id === '') ? 'New Form' : 'Policy'}}" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>

<div class="row">
  <div class="col-xl-9 col-lg-12">
    @if (customerPolicyForm) {
      <form [formGroup]="customerPolicyForm">
        @if (activeDetailIndex == 0) {
          <div class="card">
            <div class="card-header">
              <div class="d-flex">
                <h5 class="card-title flex-grow-1 mb-0">Policy</h5>
                <div *ngIf="!disabledForm" class="flex-shrink-0">
                  <div class="form-check form-switch form-switch-right form-switch-md">
                    <div class="hstack gap-2 mt-3">
                      <button (click)="hiddenFindContact=!hiddenFindContact" type="button" class="btn btn-info btn-label right"><i
                        class="ri-user-search-fill label-icon align-middle fs-16 ms-2"></i>
                        Find and select a contact</button>
                      <ng-select class="find-contact" #searchContactSelect [class]="hiddenFindContact ? 'hidden-find-contact' : 'show-find-contact'" [items]="filtered_contacts"
                                 notFoundText="Please enter 3 or more characters"
                                 bindLabel="fullSearch"
                                 bindValue="fullSearch"
                                 (search)="searchContact($event)"
                                 (change)="onSelectionChange($event)"
                                 [clearable]="false"
                                 placeholder="Search by name, email address, phone number or SSN">
                        <ng-template *ngIf="filtered_contacts.length > 0" ng-option-tmp let-item="item">
                          <div><i class="ri-user-fill"></i> {{item.first_name}} {{item.last_name}}</div>
                          <div><i class="ri-mail-fill"></i> {{item.email}}</div>
                          <div><i class="ri-phone-fill"></i> {{item.phone_number}}</div>
                          <div><i class="ri-cake-2-fill"></i> {{item.date_of_birth}}</div>
                        </ng-template>
                        <ng-template *ngIf="filtered_contacts.length == 0" ng-option-tmp let-item="item">
                          Please enter 3 or more characters
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body form-steps">
              <ng-stepper #cdkStepper [linear]="true" class="step-arrow-nav wizard" [selectedIndex]="activeStepIndex">
                <input type="text" hidden="hidden" id="status" formControlName="status">
                <input type="text" hidden="hidden" id="created_at" formControlName="created_at">
                <input type="text" hidden="hidden" id="updated_at" formControlName="updated_at">
                <cdk-step [optional]="false">
                  <ng-template cdkStepLabel>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" #customerTab id="customer-tab" data-bs-toggle="pill"
                        data-bs-target="#customer-tab" type="button" role="tab"
                      aria-controls="customer-tab" aria-selected="true">Holder</button>
                    </li>
                  </ng-template>
                  <div>
                    <div class="row">
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="first_name" class="form-label required">First name</label>
                          <div class="has-validation">
                            <input type="text" class="form-control" id="first_name" [readonly]="disabledForm"
                              aria-describedby="first_name" required formControlName="first_name"
                              [ngClass]="{'is-invalid': submit && customerPolicyForm.get('first_name')?.hasError('required')}">
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="middle_name" class="form-label">Middle name</label>
                          <input type="text" class="form-control" id="middle_name" formControlName="middle_name" [readonly]="disabledForm">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="last_name" class="form-label required">Last name</label>
                          <div class="has-validation">
                            <input type="text" class="form-control" id="last_name" [readonly]="disabledForm"
                              aria-describedby="last_name" required formControlName="last_name"
                              [ngClass]="{'is-invalid': submit && customerPolicyForm.get('last_name')?.hasError('required')}" >
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="second_last_name" class="form-label">Second last name</label>
                          <input type="text" class="form-control" id="second_last_name" formControlName="second_last_name" [readonly]="disabledForm">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="date_of_birth" class="form-label required">Date of birth</label>
                          <div class="has-validation">
                            <input type="date" class="form-control" id="date_of_birth" required formControlName="date_of_birth" [readonly]="disabledForm"
                              [ngClass]="{'is-invalid': submit && customerPolicyForm.get('date_of_birth')?.hasError('required')}" >
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="gender" class="form-label required">Gender</label>
                          <ng-select id="gender" [clearable]="false" [items]="dataSets?.genders" bindLabel="name" bindValue="name" formControlName="gender"
                                       [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('gender')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="phone_number" class="form-label required">Phone number</label>
                          <input type="tel" class="form-control" placeholder="(xxx)xxx-xxxx" mask="(000)000-0000" id="phone_number" formControlName="phone_number"
                                  [readonly]="disabledForm"
                                 [ngClass]="{'is-invalid': submit && (customerPolicyForm.get('phone_number')?.hasError('required') || customerPolicyForm.get('phone_number')?.hasError('invalidPhoneNumber'))}" >
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="email" class="form-label required">Email address</label>
                          <input type="email" class="form-control" placeholder="example@email.com" id="email" formControlName="email" required formControlName="email"
                                [readonly]="disabledForm"
                              [ngClass]="{'is-invalid': submit && customerPolicyForm.get('email')?.hasError('required')}" >
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="legal_status" class="form-label">Legal status</label>
                          <ng-select id="legal_status" [clearable]="false" [items]="dataSets?.legal_status" bindLabel="name" bindValue="name" formControlName="legal_status"
                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('legal_status')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="ssn" class="form-label">Social security number</label>
                          <div class="form-icon right full-width">
                            <input [type]="fieldTextType ? 'text' : 'password'"
                              class="form-control form-control-icon" id="ssn" formControlName="ssn" [readonly]="disabledForm"
                              [specialCharacters]="[ '[' ,']' , '-' ]" placeholder="XXX-XX-XXXX" mask="000-00-0000">
                            <a href="javascript:void(0);">
                              <i class="mdi align-middle" [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType }" (click)="toggleFieldTextType()"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="green_card" class="form-label">Green card</label>
                          <input type="text" class="form-control" id="green_card" formControlName="green_card" [readonly]="disabledForm">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="work_permit" class="form-label">Work permit</label>
                          <input type="text" class="form-control" id="work_permit" formControlName="work_permit" [readonly]="disabledForm">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="civil_status" class="form-label">Civil status</label>
                          <ng-select id="civil_status" [clearable]="false" [items]="dataSets?.civil_status" bindLabel="name" bindValue="name" formControlName="civil_status"
                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('civil_status')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="row pt-3 border-top mt-3">
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="applicant_member" class="form-label required">Is it an applicant?</label>
                          <ng-select id="applicant_member" [clearable]="false" [items]="dataSets?.yes_no" bindLabel="name" bindValue="value" formControlName="applicant_member"
                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('applicant_member')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="language" class="form-label">Language</label>
                          <ng-select id="language" [clearable]="false" [items]="dataSets?.languages" bindLabel="name" bindValue="name" formControlName="language"
                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('language')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="period" class="form-label required">Period</label>
                          <ng-select id="period" [clearable]="false" [items]="dataSets?.periods" bindLabel="name" bindValue="name" formControlName="period"
                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('period')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="row pt-3 border-top mt-3">
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="address_1" class="form-label required">Address #1</label>
                          <input type="text" class="form-control" id="address_1" formControlName="address_1" [readonly]="disabledForm"
                            [ngClass]="{'is-invalid': submit && customerPolicyForm.get('address_1')?.hasError('required')}">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="address_2" class="form-label ">Address #2</label>
                          <input type="text" class="form-control" id="address_2" formControlName="address_2" [readonly]="disabledForm">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="city" class="form-label required">City</label>
                          <input type="text" class="form-control" id="city" formControlName="city" [readonly]="disabledForm"
                            [ngClass]="{'is-invalid': submit && customerPolicyForm.get('city')?.hasError('required')}">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                            <label for="state" class="form-label required">State</label>
                            <ng-select id="state" [clearable]="false" [items]="states" bindLabel="name" bindValue="name" formControlName="state"
                                        [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                       [ngClass]="{'is-invalid': submit && customerPolicyForm.get('state')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="postal_code" class="form-label required">Postal code</label>
                          <input type="text" class="form-control" id="postal_code" formControlName="postal_code" [readonly]="disabledForm"
                            [ngClass]="{'is-invalid': submit && customerPolicyForm.get('postal_code')?.hasError('required')}">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="county" class="form-label required">County</label>
                          <input type="text" class="form-control" id="county" formControlName="county" [readonly]="disabledForm"
                            [ngClass]="{'is-invalid': submit && customerPolicyForm.get('county')?.hasError('required')}">
                        </div>
                      </div>
                    </div>
                    <div class="row pt-3 border-top mt-2">
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="name_of_employer" class="form-label required">Name of employer</label>
                          <input type="text" class="form-control" id="name_of_employer" formControlName="name_of_employer" [readonly]="disabledForm"
                                 [ngClass]="{'is-invalid': submit && customerPolicyForm.get('name_of_employer')?.hasError('required')}">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="employer_phone" class="form-label">Employer's phone number</label>
                          <input type="tel" class="form-control" placeholder="(xxx)xxx-xxxx" mask="(000)000-0000" [readonly]="disabledForm"
                            id="employer_phone" formControlName="employer_phone">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="position_occupation" class="form-label required">Position / Occupation</label>
                          <input type="text" class="form-control" id="position_occupation" formControlName="position_occupation" [readonly]="disabledForm"
                                 [ngClass]="{'is-invalid': submit && customerPolicyForm.get('annual_income')?.hasError('required')}">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="annual_income" class="form-label required">Annual income</label>
                          <div class="input-group mb-3">
                            <span class="input-group-text">$</span>
                            <input id="annual_income" class="form-control" [readonly]="disabledForm"
                              aria-label="Amount (to the nearest dollar)" formControlName="annual_income"
                              [ngClass]="{'is-invalid': submit && customerPolicyForm.get('annual_income')?.hasError('required')}">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row border-top mt-2"></div>
                  <div class="d-flex align-items-start gap-3 mt-4">
                    <button type="button" class="btn btn-success btn-label right ms-auto nexttab" data-nexttab="members-tab" cdkStepperNext>
                      <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Go to Members
                    </button>
                  </div>
                </cdk-step>
                <cdk-step [optional]="true">
                  <ng-template cdkStepLabel>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" #membersTab id="members-tab"
                        data-bs-toggle="pill" data-bs-target="#members-info" type="button"
                        role="tab" aria-controls="members-info"
                      aria-selected="false">Members</button>
                    </li>
                  </ng-template>
                  <div #StepMembers>
                    <div class="row">
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="number_applicants" class="form-label required">Number of applicants</label>
                          <input type="number" class="form-control" id="number_applicants" formControlName="number_applicants" [readonly]="disabledForm"
                            [ngClass]="{'is-invalid': submit && customerPolicyForm.get('number_applicants')?.hasError('required')}">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="number_members" class="form-label required">Number of members</label>
                          <input type="number" class="form-control" id="number_members" formControlName="number_members" [readonly]="disabledForm"
                            [ngClass]="{'is-invalid': submit && customerPolicyForm.get('number_members')?.hasError('required')}">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="number_dependents" class="form-label required">Number of dependents</label>
                          <input type="number" class="form-control" id="number_dependents" formControlName="number_dependents" [readonly]="disabledForm"
                            [ngClass]="{'is-invalid': submit && customerPolicyForm.get('number_dependents')?.hasError('required')}">
                        </div>
                      </div>
                    </div>
                    @if (!disabledForm) {
                      <div class="row">
                        <div class="col-xxl-3 col-md-3">
                          <button type="button" class="btn btn-success add-btn" id="add-member" (click)="addNewMember()"><i class="ri-add-line align-bottom me-1"></i> Add Member</button>
                        </div>
                      </div>
                    }
                    @if (current_number_members > 1) {
                      <div class="row border-top pt-3 mt-2">
                        <div class="col-lg-3">
                          <div class="" role="tablist" aria-orientation="vertical">
                            <ul ngbNav #CustomVertical="ngbNav" [(activeId)]="current_member" class="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills text-center">
                              @for (rowControl of membersRowsControls; let rowIndex = $index; track rowIndex) {
                                <li [ngbNavItem]="rowIndex" [formGroup]="getFormGroupAtIndex(rowIndex)">
                                  <a ngbNavLink (click)="selectMember(rowIndex)">Member {{rowIndex + 1}}</a>
                                  <ng-template ngbNavContent>
                                    <div class="row">
                                      <div class="col-xxl-4 col-md-6">
                                        <div class="mb-3">
                                          <label for="applicant_member_{{rowIndex}}" class="form-label required">Is this member an applicant?</label>
                                          <ng-select id="applicant_member_{{rowIndex}}" [clearable]="false" [items]="dataSets?.yes_no" bindLabel="name" bindValue="name" formControlName="applicant_member"
                                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('applicant_member_{{rowIndex}}')?.hasError('required')}"></ng-select>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row border-top pt-3 mt-2">
                                      <div class="col-xxl-4 col-md-6">
                                        <div class="mb-3">
                                          <label for="type_dependency_{{rowIndex}}" class="form-label required">Type of member</label>
                                          <ng-select id="type_dependency_{{rowIndex}}" (change)="onTypeDependencyChange($event, rowControl)"
                                            [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                          [clearable]="false" [items]="dataSets?.types_dependency" bindLabel="name" bindValue="name" formControlName="type_dependency"
                                                     [ngClass]="{'is-invalid': submit && rowControl.get('type_dependency')?.hasError('required')}"></ng-select>
                                        </div>
                                      </div>
                                      <div class="col-xxl-4 col-md-6">
                                        <div class="mb-3">
                                          <label for="first_name_{{rowIndex}}" class="form-label required">First name</label>
                                          <div class="has-validation">
                                            <input type="text" class="form-control" id="first_name_{{rowIndex}}" [readonly]="disabledForm"
                                              aria-describedby="first_name" required formControlName="first_name"
                                              [ngClass]="{'is-invalid': submit && rowControl.get('first_name')?.hasError('required')}">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-xxl-4 col-md-6">
                                        <div class="mb-3">
                                          <label for="last_name_{{rowIndex}}" class="form-label required">Last name</label>
                                          <div class="has-validation">
                                            <input type="text" class="form-control" id="last_name_{{rowIndex}}" [readonly]="disabledForm"
                                              aria-describedby="last_name" required formControlName="last_name"
                                              [ngClass]="{'is-invalid': submit && rowControl.get('last_name')?.hasError('required')}">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-xxl-4 col-md-6">
                                        <div class="mb-3">
                                          <label for="date_of_birth_{{rowIndex}}" class="form-label required">Date of birth</label>
                                          <div class="has-validation">
                                            <input type="date" class="form-control" id="date_of_birth_{{rowIndex}}" [readonly]="disabledForm"
                                              aria-describedby="date_of_birth" required formControlName="date_of_birth"
                                              [ngClass]="{'is-invalid': submit && rowControl.get('date_of_birth')?.hasError('required')}">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-xxl-4 col-md-6">
                                        <div class="mb-3">
                                          <label for="gender_{{rowIndex}}" class="form-label required">Gender</label>
                                          <ng-select id="gender_{{rowIndex}}" [clearable]="false" [items]="dataSets?.genders" bindLabel="name" bindValue="name" formControlName="gender"
                                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                                     [ngClass]="{'is-invalid': submit && rowControl.get('gender')?.hasError('required')}"></ng-select>
                                        </div>
                                      </div>
                                      <div class="col-xxl-4 col-md-6">
                                        <div class="mb-3">
                                          <label for="phone_number_{{rowIndex}}" class="form-label">Phone number</label>
                                          <input type="tel" class="form-control" placeholder="(xxx)xxx-xxxx" mask="(000)000-0000" id="phone_number_{{rowIndex}}" formControlName="phone_number"
                                                 [readonly]="disabledForm"
                                                 [ngClass]="{'is-invalid': submit && rowControl.get('phone_number')?.hasError('invalidPhoneNumber')}">
                                        </div>
                                      </div>
                                      <div class="col-xxl-4 col-md-6">
                                        <div class="mb-3">
                                          <label for="email_{{rowIndex}}" class="form-label">Email address</label>
                                          <input type="email" class="form-control" placeholder="example@email.com" id="email_{{rowIndex}}" formControlName="email" [readonly]="disabledForm">
                                        </div>
                                      </div>
                                      <div class="col-xxl-4 col-md-6">
                                        <div class="mb-3">
                                          <label for="legal_status_{{rowIndex}}" class="form-label required">Legal status</label>
                                          <ng-select id="legal_status_{{rowIndex}}" [clearable]="false" [items]="dataSets?.legal_status" bindLabel="name" bindValue="name" formControlName="legal_status"
                                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                                     [ngClass]="{'is-invalid': submit && rowControl.get('legal_status')?.hasError('required')}"></ng-select>
                                        </div>
                                      </div>
                                      <div class="col-xxl-4 col-md-6">
                                        <div class="mb-3">
                                          <label for="ssn_{{rowIndex}}" class="form-label">Social security number</label>
                                          <div class="form-icon right full-width">
                                            <input [type]="fieldTextType ? 'text' : 'password'" class="form-control form-control-icon" id="ssn_{{rowIndex}}" [readonly]="disabledForm"
                                              formControlName="ssn" [specialCharacters]="[ '[' ,']' , '-' ]" placeholder="XXX-XX-XXXX" mask="000-00-0000">
                                            <a href="javascript:void(0);">
                                              <i class="mdi align-middle" [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType }" (click)="toggleFieldTextType()"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      @if(rowControl.get('type_dependency')?.value === 'Spouse') {
                                        <div class="col-xxl-4 col-md-6">
                                          <div class="mb-3">
                                            <label for="name_of_employer_{{rowIndex}}" class="form-label">Name of employer</label>
                                            <input type="text" class="form-control" id="name_of_employer_{{rowIndex}}" formControlName="name_of_employer" [readonly]="disabledForm">
                                          </div>
                                        </div>
                                        <div class="col-xxl-4 col-md-6">
                                          <div class="mb-3">
                                            <label for="position_occupation_{{rowIndex}}" class="form-label">Position / Occupation</label>
                                            <input type="text" class="form-control" id="position_occupation_{{rowIndex}}" formControlName="position_occupation" [readonly]="disabledForm">
                                          </div>
                                        </div>
                                        <div class="col-xxl-4 col-md-6">
                                        <div class="mb-3">
                                          <label for="annual_income_{{rowIndex}}" class="form-label required">Annual income</label>
                                          <div class="input-group mb-3">
                                            <span class="input-group-text">$</span>
                                            <input id="annual_income_{{rowIndex}}" formControlName="annual_income" class="form-control" aria-label="Amount (to the nearest dollar)"
                                            [readonly]="disabledForm"
                                                   [ngClass]="{'is-invalid': submit && rowControl.get('annual_income')?.hasError('required')}">
                                          </div>
                                        </div>
                                      </div>
                                      }
                                      @if (!disabledForm) {
                                        <div class="row">
                                          <div class="col-xxl-3 col-md-3">
                                            <button type="button" class="btn btn-danger add-btn" id="delete-member" (click)="membersDeleteRow(rowIndex)"><i class="ri-delete-bin-5-line align-bottom me-1"></i> Remove</button>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  </ng-template>
                                </li>
                              }
                            </ul>
                          </div>
                        </div> <!-- end col-->
                        <div class="col-lg-9">
                          <div class="tab-content text-muted mt-3 mt-lg-0">
                            <div [ngbNavOutlet]="CustomVertical"></div>
                          </div>
                        </div> <!-- end col-->
                      </div>
                    }
                  </div>
                  <div class="row border-top mt-2"></div>
                  <div class="d-flex align-items-start gap-3 mt-4">
                    <button type="button" class="btn btn-light btn-label previestab" data-previous="members-tab" cdkStepperPrevious>
                      <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to Customer
                    </button>
                    <button type="button" class="btn btn-success btn-label right ms-auto nexttab" data-nexttab="insurance-tab" cdkStepperNext>
                      <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Go to Insurance
                    </button>
                  </div>
                </cdk-step>
                <cdk-step [optional]="true">
                  <ng-template cdkStepLabel>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" #insuranceTab id="insurance-tab"
                        data-bs-toggle="pill" data-bs-target="#steparrow-description-info" type="button"
                        role="tab" aria-controls="steparrow-description-info"
                      aria-selected="false">Insurance</button>
                    </li>
                  </ng-template>
                  <div #StepInsurance>
                    <div class="row">
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="policy_number" class="form-label">Policy number</label>
                          <input type="number" class="form-control" id="policy_number" formControlName="policy_number" [readonly]="disabledForm">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="contact_identification" class="form-label">Contact identification</label>
                          <input type="text" class="form-control" id="contact_identification" formControlName="contact_identification" [readonly]="disabledForm">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="effective_date" class="form-label required">Effective date</label>
                          <div class="has-validation">
                            <input type="date" class="form-control" id="effective_date"
                              aria-describedby="effective_date" required formControlName="effective_date" [readonly]="disabledForm"
                              [ngClass]="{'is-invalid': submit && customerPolicyForm.get('effective_date')?.hasError('required')}">
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="company" class="form-label required">Company</label>
                          <ng-select id="company" [clearable]="false" (change)="onChangeCompany($event)" [items]="dataSets?.companies" bindLabel="name" bindValue="name" formControlName="company"
                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('company')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="insurance_plan" class="form-label required">Insurance plan</label>
                          <input type="text" class="form-control" id="insurance_plan" formControlName="insurance_plan" [readonly]="disabledForm"
                            [ngClass]="{'is-invalid': submit && customerPolicyForm.get('insurance_plan')?.hasError('required')}">
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="type_plan" class="form-label">Type of plan</label>
                          <ng-select id="type_plan" [clearable]="false" [items]="dataSets?.plans" bindLabel="name" bindValue="name" formControlName="type_plan"
                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('type_plan')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="total_cost" class="form-label">Total cost</label>
                          <div class="input-group mb-3">
                            <span class="input-group-text">$</span>
                            <input id="total_cost" formControlName="total_cost" class="form-control" aria-label="Amount (to the nearest dollar)" [readonly]="disabledForm">
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="tax_credit_subsidy" class="form-label">Tax Credit / Subsidy</label>
                          <div class="input-group mb-3">
                            <span class="input-group-text">$</span>
                            <input id="tax_credit_subsidy" formControlName="tax_credit_subsidy" class="form-control" aria-label="Amount (to the nearest dollar)" [readonly]="disabledForm">
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="monthly_premium_amount" class="form-label required">Monthly premium amount</label>
                          <div class="input-group mb-3">
                            <span class="input-group-text">$</span>
                            <input id="monthly_premium_amount" formControlName="monthly_premium_amount" class="form-control" aria-label="Amount (to the nearest dollar)" [readonly]="disabledForm"
                              [ngClass]="{'is-invalid': submit && customerPolicyForm.get('monthly_premium_amount')?.hasError('required')}">
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-6 col-md-6">
                        <div class="mb-3">
                          <label for="npn_responsible" class="form-label required">NPN Responsible</label>
                          @if (disabledForm) {
                            <input type="text" class="form-control" id="npn_responsible" formControlName="npn_responsible" [readonly]="disabledForm"
                              [ngClass]="{'is-invalid': submit && customerPolicyForm.get('npn_responsible')?.hasError('required')}">
                          } @else {
                            <ng-select id="npn_responsible" [searchable]="false" [clearable]="false" [items]="dataSets?.['npns']" bindLabel="name" bindValue="value" formControlName="npn_responsible"
                                       [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                       [ngClass]="{'is-invalid': submit && customerPolicyForm.get('npn_responsible')?.hasError('required')}"></ng-select>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row border-top mt-2"></div>
                  <div class="d-flex align-items-start gap-3 mt-4">
                    <button type="button" class="btn btn-light btn-label previestab" data-previous="insurance-tab" cdkStepperPrevious>
                      <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to Members
                    </button>
                    <button type="button" class="btn btn-success btn-label right ms-auto nexttab" data-nexttab="payment-method-tab" cdkStepperNext>
                      <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Go to Payment Method
                    </button>
                  </div>
                </cdk-step>
                <cdk-step [optional]="true">
                  <ng-template cdkStepLabel>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" #paymentMethodTab id="payment-method-tab"
                        data-bs-toggle="pill" data-bs-target="#steparrow-description-info" type="button"
                        role="tab" aria-controls="steparrow-description-info"
                      aria-selected="false">Payment Method</button>
                    </li>
                  </ng-template>
                  <div #StepPaymentMethod>
                    <div class="row">
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="recurring_payment" class="form-label">Recurring payment?</label>
                          <ng-select id="recurring_payment" [clearable]="false" [items]="dataSets?.yes_no" bindLabel="name" bindValue="value" formControlName="recurring_payment"
                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('recurring_payment')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="preferred_pay_day" class="form-label">Preferred pay day</label>
                          <ng-select id="preferred_pay_day" [clearable]="false" [items]="days" bindLabel="name" bindValue="name" formControlName="preferred_pay_day"
                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('preferred_pay_day')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="payment_type" class="form-label">Type of payment method</label>
                          <ng-select id="payment_type" (change)="onSelectPayment($event)" [clearable]="false" [items]="dataSets?.payment_types" bindLabel="name" bindValue="name" formControlName="payment_type"
                                      [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('payment_type')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                    </div>
                    @if (selected_payment_method === "Credit/Debit Card") {
                      <div class="row pt-3 border-top mt-3">
                        <div class="col-xxl-3 col-md-6">
                          <div class="mb-3">
                            <label for="card_type" class="form-label">Card type</label>
                            <ng-select id="card_type" [clearable]="false" [items]="dataSets?.card_types" bindLabel="name" bindValue="name" formControlName="card_type"
                                        [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                       [ngClass]="{'is-invalid': submit && customerPolicyForm.get('card_type')?.hasError('required')}"></ng-select>
                          </div>
                        </div>
                        <div class="col-xxl-3 col-md-6">
                          <div class="mb-3">
                            <label for="card_number" class="form-label">Card number</label>
                            <input type="text" class="form-control" id="card_number" formControlName="card_number" [readonly]="disabledForm"
                              placeholder="xxxx xxxx xxxx xxxx" mask="0000 0000 0000 0000">
                          </div>
                        </div>
                        <div class="col-xxl-3 col-md-6">
                          <div class="mb-3">
                            <label for="expiration_month" class="form-label">Expiration month</label>
                            <ng-select id="expiration_month" [clearable]="false" [items]="months" bindLabel="name" bindValue="name" formControlName="expiration_month"
                                        [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                       [ngClass]="{'is-invalid': submit && customerPolicyForm.get('expiration_month')?.hasError('required')}"></ng-select>
                          </div>
                        </div>
                        <div class="col-xxl-3 col-md-6">
                          <div class="mb-3">
                            <label for="expiration_year" class="form-label">Expiration year</label>
                            <ng-select id="expiration_year" [clearable]="false" [items]="years" bindLabel="name" bindValue="name" formControlName="expiration_year"
                                        [readonly]="disabledForm" (dblclick)="dlClickSelect($event)"
                                       [ngClass]="{'is-invalid': submit && customerPolicyForm.get('expiration_year')?.hasError('required')}"></ng-select>
                          </div>
                        </div>
                        <div class="col-xxl-3 col-md-6">
                          <div class="mb-3">
                            <label for="cvv" class="form-label">CVV / CSV</label>
                            <div class="form-icon right full-width">
                              <input [type]="cvvTextType ? 'text' : 'password'" class="form-control form-control-icon" id="cvv" [readonly]="disabledForm"
                                     formControlName="cvv">
                              <a href="javascript:void(0);">
                                  <i class="mdi align-middle" [ngClass]="{'mdi-eye-off-outline': !cvvTextType, 'mdi-eye-outline': cvvTextType }" (click)="toggleCVVTextType()"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    } @else if (selected_payment_method === "EFT Bank") {
                      <div class="row pt-3 border-top mt-3">
                        <div class="col-xxl-3 col-md-6">
                          <div class="mb-3">
                            <label for="bank_name" class="form-label">Bank name</label>
                            <input type="text" class="form-control" id="bank_name" formControlName="bank_name" [readonly]="disabledForm">
                          </div>
                        </div>
                        <div class="col-xxl-3 col-md-6">
                          <div class="mb-3">
                            <label for="routing_number" class="form-label">Routing number (ABA)</label>
                            <input type="text" class="form-control" id="routing_number" formControlName="routing_number" [readonly]="disabledForm">
                          </div>
                        </div>
                        <div class="col-xxl-3 col-md-6">
                          <div class="mb-3">
                            <label for="account_number" class="form-label">Account number</label>
                            <input type="text" class="form-control" id="account_number" formControlName="account_number" [readonly]="disabledForm">
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div class="row border-top mt-2"></div>
                  <div class="d-flex align-items-start gap-3 mt-4">
                    <button type="button" class="btn btn-light btn-label previestab" data-previous="insurance-tab" cdkStepperPrevious>
                      <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to Insurance
                    </button>
                    <button type="button" class="btn btn-success btn-label right ms-auto nexttab" data-nexttab="extras-tab" cdkStepperNext>
                      <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Go to Extras
                    </button>
                  </div>
                </cdk-step>
                <cdk-step [optional]="true">
                  <ng-template cdkStepLabel>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" #extrasTab id="extras-tab"
                        data-bs-toggle="pill" data-bs-target="#extras-info" type="button"
                        role="tab" aria-controls="extras-info"
                      aria-selected="false">Extras</button>
                    </li>
                  </ng-template>
                  <div #StepExtras>
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <label for="routing_number" class="form-label">Extra notes or comments</label>
                          <ckeditor [editor]="Editor" [config]="config" data="" id="extra_notes" formControlName="extra_notes"></ckeditor>
                        </div>
                      </div>
                      <div class="col-xxl-3 col-md-6">
                        <div class="mb-3">
                          <label for="send_consent" class="form-label text-muted">Send consent by</label>
                          <ng-select id="send_consent" [clearable]="false" [items]="dataSets?.send_by" bindLabel="name" bindValue="name" formControlName="send_consent"
                                     (dblclick)="dlClickSelect($event)" [readonly]="disabledForm"
                                     [ngClass]="{'is-invalid': submit && customerPolicyForm.get('send_consent')?.hasError('required')}"></ng-select>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mb-3 form-check">
                          <input class="form-check-input" type="checkbox" id="i_agree" formControlName="i_agree"
                            [ngClass]="{'is-invalid': submit && customerPolicyForm.get('i_agree')?.hasError('required')}">
                          <label class="form-check-label required" for="i_agree">
                            I declare that I have read and reviewed (or had read to me) all the information in this application. I acknowledge with my signature that the information provided is real, voluntary, and that I did not provide false or fraudulent information. I voluntarily acknowledge that I provided the information to the insurance agent to apply for the Health Marketplace. I authorize the insurance agent to use the information for health, life insurance, and other services.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row border-top mt-2"></div>
                  <div class="d-flex align-items-start gap-3 mt-4">
                    <button type="button" class="btn btn-light btn-label previestab" data-previous="members-tab" cdkStepperPrevious>
                      <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to Payment Method
                    </button>
                    @if (!disabledForm) {
                      <div class="right ms-auto">
                        <button (click)="validSubmit(true)" type="submit" class="btn draft btn-label ms-auto">
                          <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Save Draft
                        </button>
                        @if (id != null) {
                          <button (click)="validSubmit()" type="submit" class="btn to-process btn-label right ml-10">
                            <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>To Process
                          </button>
                        }
                      </div>
                    }
                  </div>
                </cdk-step>
              </ng-stepper>
            </div>
            <!-- end card body -->
          </div>
        }
        <!-- end card -->
        @if (activeDetailIndex == 1) {
          <div class="card">
            <div class="card-header">
              <div class="d-flex">
                <h5 class="card-title flex-grow-1 mb-0">Documents</h5>
                <div class="flex-shrink-0">
                  <div class="col-sm-auto">
                    <div>
                      <button type="button" class="btn btn-success add-btn me-1" data-bs-toggle="modal" id="create-btn"
                              data-bs-target="#showModal"
                              (click)="showNewDoc = !showNewDoc"><i class="ri-add-line align-bottom me-1"></i> New document</button>
                    </div>
                  </div>
                </div>
                </div>
                <div class="card-body" [style]="!showNewDoc ? 'padding: 0' : ''">
                  @if (showNewDoc) {
                    <div class="dropzone"
                      amk_dropzone
                      (hovered)="toggleHover($event)"
                      (dropped)="onDrop($event)"
                      (click)="fileInput.click()"
                      [class.hovering]="isHovering">
                      <h3>Documents Drop Zone</h3>
                      <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)" multiple>
                      <p class="text-muted">Drag and drop files here</p>
                    </div>
                  }
                  @if (showNewDoc) {
                    <h3>Uploads</h3>
                  }
                  @for (file of files; track file) {
                    <div>
                      <upload-task [file]="file" [upload_by]="current_user['full_name']" [belong_to]="customerPolicyForm.controls['first_name'].value + ' ' + customerPolicyForm.controls['last_name'].value" [policy_id]="id ? id : ''" (uploadComplete)="uploadComplete(file)"></upload-task>
                    </div>
                  }
                </div>
              </div>
              <div class="card-body">
                <app-policy-files [policy_id]="id" [policy_files]="policy_files" [status]="current_policy?.['status']"></app-policy-files>
              </div>
            </div>
          }
          <div *ngIf="activeDetailIndex == 2" class="card">
            <div class="card-header">
              <div class="d-flex">
                <h5 class="card-title flex-grow-1 mb-0">Notes</h5>
              </div>
            </div>
            <div class="card-body p-4">
              <app-chat [policyId]="id" [currentUser]="current_user" [policy_comments]="policy_comments"></app-chat>
            </div>
          </div>
          @if (activeDetailIndex == 3) {
            <div class="card">
              <div class="card-header">
                <div class="d-flex">
                  <h5 class="card-title flex-grow-1 mb-0">Reminders</h5>
                </div>
              </div>
            </div>
          }
          @if (activeDetailIndex == 4) {
            <div class="card">
              <div class="card-header">
                <div class="d-flex">
                  <h5 class="card-title flex-grow-1 mb-0">Logs</h5>
                </div>
              </div>
            </div>
          }
          <!-- end card -->
        </form>
      }
    </div>
    <div class="col-xl-3">
      <div class="card">
        <div class="card-header">
          <div class="d-flex">
            <h5 class="card-title flex-grow-1 mb-0">Policy Details</h5>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive table-card">
            <table class="table table-borderless align-middle mb-0 right-table-details">
              <tbody>
                <tr>
                  <td class="fw-medium">Agent:</td>
                  <td>{{current_policy?.['agent_name'] | titlecase}}</td>
                </tr>
                <tr>
                  <td class="fw-medium">Status:</td>
                  <td>
                    @if(!isProcessor) {
                      <span class="font-size-12 badge bg-primary">{{customerPolicyForm.controls['status'].value | statusName}}</span>
                    } @else if(isProcessor) {
                      <ng-select (change)="onChangeStatus($event, NoticeSignModal)" [searchable]="false" [clearable]="false" [items]="dataSets?.policy_status" bindLabel="name"
                                  bindValue="value" [(ngModel)]="customerPolicyForm.controls['status'].value"></ng-select>
                    }
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Last update:</td>
                  <td>{{ customerPolicyForm.controls['updated_at'].value | date:'MMM d, y, h:mm a' }}</td>
                </tr>
                <!--                            May 20, 2024 05:27 pm-->
                @if (id) {
                  <tr>
                    <td class="fw-medium">Code:</td>
                    <td><small>{{id}}</small></td>
                  </tr>

                  <tr>
                    <td class="fw-medium">Consent:</td>
                    <td>
                      <div class="d-flex">
                        @if(current_policy?.['consent_sent']) {
                          <span class="status-consent" [class.sent]="current_policy?.['consent_sent']">
                            <i class="ri-mail-send-fill" ngbTooltip="Sent Consent"></i>
                          </span>
                        }

                        @if(current_policy?.['signed_consent']) {
                          <span class="status-consent ml-5" [class.signed]="current_policy?.['signed_consent']">
                            <i class="ri-mail-check-fill" ngbTooltip="Signed Consent"></i>
                          </span>
                        }

                        @if(current_policy?.['confirmed_consent']) {
                          <span class="status-consent ml-5" [class.confirmed]="current_policy?.['confirmed_consent']">
                            <i class="ri-checkbox-circle-line" ngbTooltip="Confirmed Consent"></i>
                          </span>
                        }
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
            <div *ngIf="current_policy?.['consent_sent']" class="p-15">
              <button *ngIf="isAdmin || permissions?.includes('copy_link')" type="button" class="btn btn-primary btn-icon waves-effect waves-light"
                      (click)="copyConsentLink()"><i
                class="ri-links-line"></i></button>
              @if(current_policy?.['signed_consent']) {
              <button type="button" class="btn btn-primary btn-icon waves-effect waves-light ml-5"
                      (click)="viewConsentLink()"><i
                class="ri-eye-line"></i></button>
              }
              @if(!current_policy?.['signed_consent']) {
              <button *ngIf="!isProcessor" type="button" class="ml-10 btn btn-primary btn-label" (click)="forwardConsentLink()"><i
                class="ri-mail-send-fill label-icon align-middle fs-16 me-2"></i>
                Forward Consent</button>
              }
            </div>
          </div>
        </div>
        <div class="card-body border-top">
          <button (click)="activeDetailIndex=0" type="button" class="btn {{activeDetailIndex === 0 ? 'btn-soft-secondary' : 'btn-ghost-dark'}} waves-effect waves-light shadow-none full-width left-text">Policy form</button>
          @if (id != null) {
            <button (click)="activeDetailIndex=1" type="button" class="mt-10 btn btn-badge {{activeDetailIndex === 1 ? 'btn-soft-secondary' : 'btn-ghost-dark'}} waves-effect waves-light shadow-none full-width left-text">Documents <span class="badge bg-info-subtle text-info">{{policy_files.length}}</span></button>
          }
          @if (id != null) {
            <button (click)="goTo(2)" type="button" class="mt-10 btn btn-badge {{activeDetailIndex === 2 ? 'btn-soft-secondary' : 'btn-ghost-dark'}} waves-effect waves-light shadow-none full-width left-text">Notes @if (new_comments) {
              <span class="new-notes badge bg-success">New messages</span>
              } <span class="badge bg-info-subtle text-info">{{number_comments}}</span></button>
            }
            @if (id != null) {
              <button (click)="activeDetailIndex=3" type="button" class="mt-10 btn btn-badge {{activeDetailIndex === 3 ? 'btn-soft-secondary' : 'btn-ghost-dark'}} waves-effect waves-light shadow-none full-width left-text">Reminders <span class="badge bg-info-subtle text-info">0</span></button>
            }
            @if (id != null) {
              <button (click)="activeDetailIndex=4" type="button" class="mt-10 btn {{activeDetailIndex === 4 ? 'btn-soft-secondary' : 'btn-ghost-dark'}} waves-effect waves-light shadow-none full-width left-text">Logs</button>
            }
          </div>
        </div><!--end card-->
      </div>
    </div>

<ng-template #NoticeSignModal let-modal>
  <div class="modal-body text-center p-5">
    <lord-icon src="https://cdn.lordicon.com/tdrtiskw.json" trigger="loop"
               colors="primary:#f7b84b,secondary:#405189" style="width:130px;height:130px"></lord-icon>
    <div class="mt-4">
      <h4 class="mb-3">Notice</h4>
      <p class="text-muted mb-4">The policy you are trying to process does not have a signed consent and/or does not have documents attached, are you sure to continue?</p>
      <div class="hstack gap-2 justify-content-center">
        <a href="javascript:void(0);" class="btn btn-link link-success fw-medium shadow-none"
           data-bs-dismiss="modal" (click)="cancelStatusChange(modal)">Cancel</a>
        <a (click)="accept_Same_Way(); modal.close('accept click')" class="btn btn-success">Accept</a>
      </div>
    </div>
  </div>
</ng-template>
