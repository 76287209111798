import { Component, Input, OnInit } from '@angular/core';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { DatePipe, DecimalPipe, SlicePipe } from "@angular/common";
import { RouterLink } from "@angular/router";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { Lightbox } from "ngx-lightbox";
import { FacadeService } from "../services/facade/facade.service";

@Component({
  selector: 'app-policy-files',
  standalone: true,
    imports: [
    SlicePipe,
    DatePipe,
    RouterLink,
    DecimalPipe,
    NgbTooltip
],
  templateUrl: './policy-files.component.html',
  styleUrl: './policy-files.component.scss'
})
export class PolicyFilesComponent implements OnInit {
    @Input() policy_id: string | null = '';
    @Input() policy_files: any[] = [];
    @Input() status: string | undefined = '';

    constructor(private facadeService: FacadeService,
                private storage: AngularFireStorage,
                private lightbox: Lightbox) {}

    ngOnInit(): void {

    }

    viewFile(file: any) {
        if(file['type'].includes('image')) {
            const images = [{ src: file['downloadURL'], thumb: file['downloadURL'] }];
            this.lightbox.open(images, 0, { });
        } else if (file['type'].includes('pdf')) {
            window.open(file['downloadURL'], '_blank');
        }
    }

    downloadFile(downloadURL: string, name: string) {
        const link = document.createElement('a');
        link.href = downloadURL;
        link.target = '_blank';
        link.download = name || 'archivo';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    deleteFile(file: any) {
        const fileRef = this.storage.refFromURL(file['downloadURL']);
        fileRef.delete().toPromise().then(() => {
            this.facadeService.policyService.deletePolicyFile(this.policy_id, file['id']).subscribe(() => {
                console.log('Archivo eliminado con éxito');
            });
        }).catch(error => {
            console.error('Error al eliminar el archivo:', error);
        });
    }
}
