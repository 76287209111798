import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PolicyComponent } from "./policy.component";
import { NewPolicyComponent } from "./new-policy/new-policy.component";
import { AdminGuard } from "../../core/guards/admin.guard";

const routes: Routes = [
  {
    path: '',
    component: PolicyComponent,
  },
  {
    path: 'new-policy',
    component: NewPolicyComponent
  },
  {
    path: 'policy/:id',
    component: NewPolicyComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PolicyRoutingModule { }
