<app-breadcrumbs title="Policies" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>

<div class="row">
  <div class="col-xl-3 col-lg-4">
    <div class="card">
      <div class="card-header">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h5 class="fs-16">Filters</h5>
          </div>
          <div class="flex-shrink-0">
            <a (click)="clearAll($event)" class="text-decoration-underline">Clear All</a>
          </div>
        </div>
      </div>

      <div class="accordion accordion-flush">

        <div ngbAccordion activeIds="agents" [closeOthers]="true">
          <div ngbAccordionItem id="agents">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton class="border-0 bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                <span class="text-muted text-uppercase fs-13 fw-medium">Agents</span>
                @if(filters.agents.length > 0){
                  <span class="badge bg-success rounded-pill align-middle ms-1">{{filters.agents.length}}</span>
                }
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="accordion-body accordion-body-filters">
                    <ul class="list-unstyled mb-0 filter-list">
                      <li *ngFor="let agent of filters.agents | keyvalue">
                        <a href="javascript:void(0);" class="d-flex py-1 align-items-center"
                           [ngClass]="{'selected-filter': selectedFilter?.type === 'agent' && selectedFilter?.value === agent.key}"
                           (click)="applyFilter('agent', agent.key)">
                          <div class="flex-grow-1">
                            <h5 class="fs-13 mb-0 listname">{{ agent.key }}</h5>
                          </div>
                          <div class="flex-shrink-0 ms-2">
                            <span class="badge bg-light text-muted">{{ agent.value }}</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <div ngbAccordion activeIds="states" [closeOthers]="true">
          <div ngbAccordionItem id="states">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton class="border-0 bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                <span class="text-muted text-uppercase fs-13 fw-medium">States</span>
                @if(filters.states.length > 0){
                  <span class="badge bg-success rounded-pill align-middle ms-1">{{filters.states.length}}</span>
                }
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="accordion-body accordion-body-filters">
                    <ul class="list-unstyled mb-0 filter-list">
                      <li *ngFor="let state of filters.states | keyvalue">
                        <a href="javascript:void(0);" class="d-flex py-1 align-items-center"
                           [ngClass]="{'selected-filter': selectedFilter?.type === 'state' && selectedFilter?.value === state.key}"
                           (click)="applyFilter('state', state.key)">
                          <div class="flex-grow-1">
                            <h5 class="fs-13 mb-0 listname">{{ state.key }}</h5>
                          </div>
                          <div class="flex-shrink-0 ms-2">
                            <span class="badge bg-light text-muted">{{ state.value }}</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <div ngbAccordion activeIds="companies" [closeOthers]="true">
          <div ngbAccordionItem id="companies">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton class="border-0 bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                <span class="text-muted text-uppercase fs-13 fw-medium">Companies</span>
                @if(filters.companies.length > 0){
                  <span class="badge bg-success rounded-pill align-middle ms-1">{{filters.companies.length}}</span>
                }
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="accordion-body accordion-body-filters">
                    <ul class="list-unstyled mb-0 filter-list">
                      <li *ngFor="let company of filters.companies | keyvalue">
                        <a href="javascript:void(0);" class="d-flex py-1 align-items-center"
                           [ngClass]="{'selected-filter': selectedFilter?.type === 'company' && selectedFilter?.value === company.key}"
                           (click)="applyFilter('company', company.key)">
                          <div class="flex-grow-1">
                            <h5 class="fs-13 mb-0 listname">{{ company.key }}</h5>
                          </div>
                          <div class="flex-shrink-0 ms-2">
                            <span class="badge bg-light text-muted">{{ company.value }}</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

          <div ngbAccordion activeIds="processors" [closeOthers]="true">
              <div ngbAccordionItem id="processors">
                  <h2 ngbAccordionHeader>
                      <button ngbAccordionButton class="border-0 bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                          <span class="text-muted text-uppercase fs-13 fw-medium">Processors</span>
                          @if(filters.processors.length > 0){
                              <span class="badge bg-success rounded-pill align-middle ms-1">{{filters.processors.length}}</span>
                          }
                      </button>
                  </h2>
                  <div ngbAccordionCollapse>
                      <div ngbAccordionBody>
                          <ng-template>
                              <div class="accordion-body accordion-body-filters">
                                  <ul class="list-unstyled mb-0 filter-list">
                                      <li *ngFor="let processor of filters.processors | keyvalue">
                                          <a href="javascript:void(0);" class="d-flex py-1 align-items-center"
                                             [ngClass]="{'selected-filter': selectedFilter?.type === 'processor' && selectedFilter?.value === processor.key}"
                                             (click)="applyFilter('processor', processor.key)">
                                              <div class="flex-grow-1">
                                                  <h5 class="fs-13 mb-0 listname">{{ processor.key }}</h5>
                                              </div>
                                              <div class="flex-shrink-0 ms-2">
                                                  <span class="badge bg-light text-muted">{{ processor.value }}</span>
                                              </div>
                                          </a>
                                      </li>
                                  </ul>
                              </div>
                          </ng-template>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->

  <div class="col-xl-9 col-lg-8">
    <div>
      <div class="card">
        <div class="card-header border-0">
          <div class="row g-4">
            <div class="col-sm-auto">
              <div *ngIf="!isProcessor">
                <a routerLink="/policies/new-policy" class="btn btn-success"><i
                class="ri-add-line align-bottom me-1"></i> New Policy</a>
              </div>
            </div>

            <div *ngIf="isAdmin && selectedPolicies.length > 0" class="col-sm-auto">
              <ng-select class="w-lg" [searchable]="false" [clearable]="false" [items]="processors"
                         (change)="onProcessorChange($event)"
                         bindLabel="name" bindValue="id"></ng-select>
            </div>
            <div *ngIf="isAdmin && selectedPolicies.length > 0" class="col-sm-auto">
              <button class="btn btn-primary" [disabled]="selectedPolicies.length === 0" (click)="assignToProcessor()">
                Assign to Processor
              </button>
            </div>
            <div class="col-sm">
              <div class="d-flex justify-content-sm-end">
                <div class="search-box ms-2">
                  <input type="text" name="searchTerm" class="form-control"
                    placeholder="Search Policies..." [(ngModel)]="searchTerm"
                    (ngModelChange)="performSearch()">
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row align-items-center">
            <div class="col">
              <!-- Nav tabs -->
              <ul ngbNav #nav="ngbNav" [activeId]="1" (navChange)="onNavChange($event)"
                class="nav nav-tabs-custom card-header-tabs border-bottom-0">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>
                    All <span
                  class="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">{{ allPolicy.length }}</span>
                </a>
                <ng-template ngbNavContent>
                  <app-policy-row [current_user]="current_user" [policies]="allPolicy" [permissions]="permissions" [isAdmin]="isAdmin" [isProcessor]="isProcessor"
                                  [processors]="processors"
                    (onSort)="onSort($event)" (onCheckboxChange)="onCheckboxChange($event)"
                    (onChangePage)="changePage()"></app-policy-row>
                </ng-template>
              </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>
                    To Process <span
                    class="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">{{ toProcessPolicy.length }}</span>
                  </a>
                  <ng-template ngbNavContent>
                    <app-policy-row [current_user]="current_user" [policies]="toProcessPolicy" [permissions]="permissions" [isAdmin]="isAdmin" [isProcessor]="isProcessor"
                                    (onSort)="onSort($event)" (onCheckboxChange)="onCheckboxChange($event)" [processors]="processors"
                                    (selectedPoliciesChange)="selectedPoliciesChange($event)"
                                    (onChangePage)="changePage()"></app-policy-row>
                  </ng-template>
                </li>
                <li *ngIf="isProcessor || isAdmin" [ngbNavItem]="3">
                  <a ngbNavLink>
                    Processing <span
                    class="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">{{ processingPolicy.length }}</span>
                  </a>
                  <ng-template ngbNavContent>
                    <app-policy-row [current_user]="current_user" [policies]="processingPolicy" [permissions]="permissions" [isProcessor]="isProcessor"
                                    (onSort)="onSort($event)" (onCheckboxChange)="onCheckboxChange($event)" [processors]="processors"
                                    (onChangePage)="changePage()"></app-policy-row>
                  </ng-template>
                </li>
              <li [ngbNavItem]="4">
                <a ngbNavLink>
                  By Correction <span
                class="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">{{ byCorrectionPolicy.length }}</span>
              </a>
              <ng-template ngbNavContent>
                <app-policy-row [current_user]="current_user" [policies]="byCorrectionPolicy" [permissions]="permissions" [isAdmin]="isAdmin" [isProcessor]="isProcessor"
                  (onSort)="onSort($event)" (onCheckboxChange)="onCheckboxChange($event)" [processors]="processors"
                                (selectedPoliciesChange)="selectedPoliciesChange($event)"
                                (onChangePage)="changePage()"></app-policy-row>
              </ng-template>
            </li>
            <li *ngIf="!isProcessor" [ngbNavItem]="5">
              <a ngbNavLink>
                Draft <span
              class="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">{{ draftPolicy.length }}</span>
            </a>
            <ng-template ngbNavContent>
              <app-policy-row [current_user]="current_user" [policies]="draftPolicy" [permissions]="permissions" [isProcessor]="isProcessor"
                              (onSort)="onSort($event)" (onCheckboxChange)="onCheckboxChange($event)"
                              (onChangePage)="changePage()"></app-policy-row>
            </ng-template>
          </li>

        <li [ngbNavItem]="6">
            <a ngbNavLink>
              Processed <span
            class="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">{{ processedPolicy.length }}</span>
          </a>
          <ng-template ngbNavContent>
            <app-policy-row [current_user]="current_user" [policies]="processedPolicy" [permissions]="permissions" [isProcessor]="isProcessor"
                            (onSort)="onSort($event)" (onCheckboxChange)="onCheckboxChange($event)" [processors]="processors"
                            (onChangePage)="changePage()"></app-policy-row>
          </ng-template>
        </li>
        <li *ngIf="!isProcessor" [ngbNavItem]="7">
          <a ngbNavLink>
            Archived <span
          class="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">{{ archivedPolicy.length }}</span>
        </a>
        <ng-template ngbNavContent>
          <app-policy-row [current_user]="current_user" [policies]="archivedPolicy" [permissions]="permissions" [isAdmin]="isAdmin" [isProcessor]="isProcessor"
            (onSort)="onSort($event)" (onCheckboxChange)="onCheckboxChange($event)" [processors]="processors"
                          (selectedPoliciesChange)="selectedPoliciesChange($event)"
                          (onChangePage)="changePage()"></app-policy-row>
        </ng-template>
      </li>
      <li *ngIf="!isProcessor" [ngbNavItem]="8">
        <a ngbNavLink>
          Canceled <span
        class="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">{{ cancelledPolicy.length }}</span>
      </a>
      <ng-template ngbNavContent>
        <app-policy-row [current_user]="current_user" [policies]="cancelledPolicy" [permissions]="permissions" [isProcessor]="isProcessor"
          (onSort)="onSort($event)" (onCheckboxChange)="onCheckboxChange($event)" [processors]="processors"
        (onChangePage)="changePage()"></app-policy-row>
      </ng-template>
    </li>
  </ul>
</div>

<!-- Tab panes -->
<div class="tab-content text-muted mt-3">
  @if (is_loading) {
    <div  id="elmLoader">
      <div class="spinner-border text-primary avatar-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  }
  <div [ngbNavOutlet]="nav"></div>
</div>
</div>
</div>
</div>
<!-- end card -->
</div>
</div>
<!-- end col -->
</div>
<!-- end row -->

<!-- removeItemModal -->
<ng-template #content let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
      (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="mt-2 text-center">
        <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
          <h4>Are you sure ?</h4>
          <p class="text-muted mx-4 mb-0">Are you sure you want to remove this policy?</p>
        </div>
      </div>
      <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
        <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal"
          (click)="modal.close('Close click')">Close
        </button>
        <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="deleteData(deleteId)"
          (click)="modal.close('Close click')">Yes, Delete It!
        </button>
      </div>
    </div>
  </div><!-- /.modal-content -->
</ng-template>
