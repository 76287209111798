<div class="live-preview">
  <div class="table-responsive">
    <table class="table table-striped table-nowrap align-middle mb-0">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">File name</th>
          <th scope="col">Last update</th>
          <th scope="col">Uploaded by</th>
          <th scope="col">Belongs to</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        @for (file of policy_files; track $index) {
          <tr>
            <td class="fw-medium">{{$index + 1}}</td>
            <td>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h5 class="fs-14 mb-1">
                    {{file.name }}
                  </h5>
                  <p class="text-muted mb-0">
                    <span class="fs-12">Size: {{file.size | number: '2.1-2' }} Kb</span>
                  </p>
                </div>
              </div>
            </td>
            <td>{{file.created_at | date:'MMM d, y hh:mm a' }}</td>
            <td>{{file.uploaded_by}}</td>
            <td>{{file.belong_to}}</td>
            <td>
              <ul class="list-inline hstack gap-2 mb-0">
                <li class="list-inline-item" ngbTooltip="View" placement="top">
                  <a class="edit-item-btn" data-bs-toggle="modal" (click)="viewFile(file)"><i class="ri-eye-fill align-bottom text-muted fs-18"></i></a>
                </li>
                <li class="list-inline-item" ngbTooltip="Download" placement="top">
                  <a class="edit-item-btn" data-bs-toggle="modal" (click)="downloadFile(file['downloadURL'], file['name'])"><i class="ri-download-fill align-bottom text-muted fs-18"></i></a>
                </li>
                <li class="list-inline-item me-0" ngbTooltip="Delete" placement="top">
                  <a class="edit-item-btn" data-bs-toggle="modal" (click)="deleteFile(file)">
                    <i class="ri-delete-bin-fill align-bottom text-muted fs-18"></i>
                  </a>
                </li>
              </ul>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
