import { Pipe, PipeTransform } from '@angular/core';
import { FacadeService } from '../services/facade/facade.service';

@Pipe({
  name: 'processName',
  standalone: true
})
export class ProcessNamePipe implements PipeTransform {
  transform(value: number, processors: any[]): string {
    const processor = processors.find(p => p.id === value);
    return processor ? `(${processor.first_name} ${processor.last_name})` : '';
  }
}
