import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusName',
  standalone: true
})
export class StatusNamePipe implements PipeTransform {

  private statusMap: { [key: string]: {name: string, class: string} } = {
    'draft': {name: 'Draft', class: 'draft'},
    'cancelled': {name: 'Cancelled', class: 'cancelled'},
    'archived': {name: 'Archived', class: 'archived'},
    'duplicated': {name: 'Duplicated', class: 'duplicated'},
    'to_process': {name: 'To Process', class: 'to-process'},
    'by_correction': {name: 'By Correction', class: 'by-correction'},
    'processed': {name: 'Processed', class: 'processed'}
  };

  transform(value: string, format: string = 'name'): string {
    if(value === 'processing') {
      value = 'to_process';
    }

    if (format === 'class') {
      return this.statusMap[value]?.class || '';
    } else {
      return this.statusMap[value]?.name || value;
    }
  }

}
