import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FacadeService } from "../../../shared/services/facade/facade.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-policy-row',
  templateUrl: './policy-row.component.html',
  styleUrl: './policy-row.component.scss'
})
export class PolicyRowComponent implements OnChanges {
    @Input() permissions: any = [];
    @Input() processors: any = [];
    @Input() policies: any = [];
    @Input() isAdmin: boolean = false;
    @Input() isProcessor: boolean = false;
    @Input() current_user: any;
    @Output() onSort = new EventEmitter();
    @Output() onCheckboxChange = new EventEmitter();
    @Output() onChangePage = new EventEmitter();
    @Output() selectedPoliciesChange = new EventEmitter<any[]>();

    selectedPolicies: any[] = [];
    masterSelected!: boolean;

    paginatedPolicies: any[] = [];
    page = 1;
    pageSize = 10;
    totalPolicies = 0;
    duplicatedPolicy : any;

    constructor(public facadeService: FacadeService, private modalService: NgbModal) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['policies']) {
          this.policies = changes['policies'].currentValue.map((policy: any) => ({
            ...policy,
            isChecked: false
          }));

          this.totalPolicies = this.policies.length;
          this.updatePaginatedPolicies();
        }
    }

    checkUncheckAll(ev: any) {
      this.masterSelected = ev.target.checked;

      this.policies.forEach((policy: any) => {
        policy.isChecked = this.masterSelected;
      });

      this.selectedPolicies = this.masterSelected ? this.policies.map((policy: any) => policy.id) : [];
      this.selectedPoliciesChange.emit(this.selectedPolicies);
    }

    updatePaginatedPolicies() {
      const start = (this.page - 1) * this.pageSize;
      const end = start + this.pageSize;
      this.paginatedPolicies = this.policies.slice(start, end);
    }

    sort(field: string) {
        this.onSort.emit(field);
    }

    checkboxChange(event: any, policy: any) {
      policy.isChecked = event.target.checked;

      if (policy.isChecked) {
        this.selectedPolicies.push(policy.id);
      } else {
        this.selectedPolicies = this.selectedPolicies.filter(pId => pId !== policy.id);
      }
      this.selectedPoliciesChange.emit(this.selectedPolicies);
    }

    onPageChange(page: number) {
      this.page = page;
      this.updatePaginatedPolicies();
    }

    toChangePolicyState(id: string, status: string) {
        this.facadeService.policyService.changePolicyStatus(id, status).subscribe(() => {
            this.facadeService.messageService.add({ severity: 'success', summary: 'Success', detail: 'Policy ' + status + ' successfully' });
        });
    }

    accept_same_way() {
      this.duplicatedPolicy.status = 'draft';

      this.facadeService.policyService.addPolicy(this.duplicatedPolicy, this.current_user, true).subscribe((policyId) => {
        if(policyId) {
          this.facadeService.messageService.add({ severity: 'success', summary: 'Success', detail: 'Policy duplicated successfully' });
        }
      });
    }

    cancelStatusChange(modal: any) {
      modal.close('Close click')
    }

    confirm_duplicate(data: any, NoticeSignModal: any) {
      this.duplicatedPolicy = {...data} as any;
      this.modalService.open(NoticeSignModal, { centered: true });
    }
}
